/* eslint-disable react/style-prop-object */
import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const GrazeButton: FunctionComponent<GrazeButton> = ({ text, link, internal = true }) => {
  const GButton = () => (
    <Button variant="contained" className="graze-button">
      <div className="graze-text">{text}</div>
    </Button>
  );

  if (internal) {
    return (
      <Link to={link} role="button" className="special-link">
        <GButton />
      </Link>
    );
  }
  return (
    <a role="button" href={link} className="special-link">
      <GButton />
    </a>
  );
};

const HomePage = () => (
  <div className="home-page">
    <div className="button-group">
      <div className="data-container">
        <Header />
        <GrazeButton text="Order Here" link="https://order.chatfood.io/graze" internal={false} />
        <GrazeButton
          text="Food Safety"
          link="https://drive.google.com/drive/folders/1nt4hjkQVsfr0XtIGscRNXcJ3KSOepcQI"
          internal={false}
        />
        <GrazeButton text="Call Us" link="/call-us" />
      </div>
      <Footer />
    </div>
  </div>
);

export default HomePage;
