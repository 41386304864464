import React from 'react';

const Footer = () => (
  <div className="footer social-media-group">
    <a href="https://www.instagram.com/graze.brgr/">
      <img alt="@graze.burger" src="instagram.png" height="80" width="80" />
    </a>
    <a href="https://vm.tiktok.com/ZSJd56jcY/">
      <img alt="@graze.burger" src="tiktok.png" height="80" width="80" />
    </a>
    <a href="https://www.facebook.com/p/grazeburger-100063962373628/">
      <img alt="@graze.burger" src="facebook.png" height="80" width="80" />
    </a>
  </div>
);

export default Footer;
